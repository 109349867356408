<template>
  <el-row class="item">
    <Breadcrumb :sn="sn"></Breadcrumb>
    <el-steps :active="active" simple>
      <el-step title="售后申请" icon="round_solid1"></el-step>
      <el-step title="平台受理" icon="round_solid2"></el-step>
      <el-step   v-if="info.status==='已通过'" title="退款成功" icon="round_solid3"></el-step>
      <el-step   v-else-if="info.status==='不通过'" :title="info.status" icon="round_solid3"></el-step>
      <el-step   v-else  title="退款成功" icon="round_solid3"></el-step>
    </el-steps>
    <el-row class="content">
      <el-col class="contentLeft" :span="14">
        <div class="contentLeftTop">
          <p>
            <span class="text-left">{{ info.status }}</span>
            <span  v-if="active===1" class="text-right" @click="RefundCancelFun">撤销申请</span>
          </p>
          <p><span  v-if="active!=0" >退款审核时间：{{info.examineTime==""?"等待审核中":info.examineTime}}</span></p>
        </div>
        <div class="contentLeftBottom">
          <div class="backLogisticsBox" v-if="active===2 && info.drawMethod === 2 && info.status == '受理中'" >
              <h3>填写退回物流信息</h3>
              <div class="wraper">
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                >
                  <el-form-item class="logiNo" label="运单号：" prop="logiNo">
                    <el-input
                      placeholder="请输入退货运单号"
                      v-model="ruleForm.logiNo"
                      type="text"
                      autocomplete="off"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      :maxlength="20"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item class="logi" label="物流公司：" prop="logi">
                    <el-input
                      placeholder="请输入退货单物流公司"
                      v-model="ruleForm.logi"
                      type="text"
                      autocomplete="off"
                      :maxlength="20"
                    >
                    </el-input>
                  </el-form-item>
                 <el-form-item label="公司名称：" v-if="shippingDetail">
                    <el-col :span="8">
                      <span>{{shippingDetail.companyName}}</span>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="退货地址：" v-if="shippingDetail">
                    <el-col :span="16">
                      <span>{{shippingDetail.provienceName}}{{shippingDetail.cityName}}{{shippingDetail.boroughName}}{{shippingDetail.address}}</span>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="收货人：" v-if="shippingDetail">
                    <el-col :span="8">
                      <span>{{shippingDetail.consigneeName}}</span>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="联系方式：" v-if="shippingDetail">
                    <el-col :span="8">
                      <span>{{shippingDetail.consigneeTel}}</span>
                    </el-col>
                  </el-form-item>
                  <el-form-item>
                      <el-button     :class="!ruleForm.logiNo || !ruleForm.logi  ? 'SureBtn disabled' : 'SureBtn'"   @click.native="submitForm('ruleForm')"
                        >确定</el-button
                      >
                      <el-button class="resetBtn" @click.native="resetForm('ruleForm')">重置</el-button>
                  </el-form-item>
              </el-form>
              </div>
             
              <!-- <div class="backCon">
                <span class="backTit">退回运单号：</span>
                <el-input
                      placeholder="请输入运单号"
                      type="text"
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                      :maxlength="20"
                    >
                    </el-input>
              </div>
              <div class="backCon">
                <span class="backTit">物流公司：</span>
                少时诵诗书所所
              </div> -->
          </div>
          <div class="backCon">
            <span class="backTit">退款金额：</span>
            <span class="backMsg">{{ info.num | numAdd }}</span>
          </div>
          <div class="backCon" v-if="active>1 && info.status!='不通过'">
            <span class="backTit">退款方式：</span>
            <span class="backMsg">{{ info.acount==1?"退回到账户余额":"退回到优惠券" }}</span>
          </div>
          <div class="backCon">
            <span class="backTit">售后单号：</span>
            <span class="backMsg">{{ backId }}</span>
          </div>
          <div class="backCon">
            <span class="backTit">申请时间：</span>
            <span class="backMsg"> {{ info.date }} </span>
          </div>
          <div class="backCon">
            <span class="backTit">售后类型：</span>
            <span class="backMsg">{{ info.type === 1 ? "退款" : "退货退款"  }}</span>
          </div>

         <div class="backCon"  v-if=" info.type !== 1">
            <span class="backTit">退货方式：</span>
            <span class="backMsg">{{info.drawMethod === 1 ? "拒收" : info.drawMethod === 2 ? "寄回" : "未收货"  }}</span>
          </div>
          <div class="backCon">
            <span class="backTit">问题描述：</span>
            <span class="backMsg">{{ info.cause }}</span>
          </div>
          <div class="backCon">
            <span class="backTit">图片：</span>
            <span v-for="(item, index) in info.imgList" :key="index" class="imgBox">
              <img  :src="item  | defaultImage" alt="图片"/>
            </span>
          </div>


        </div>
      </el-col>
      <el-col class="contentRight" :span="10">
        <div class="contentRightTop">
          <p>
            <span class="text-left">申请商品详情</span>
          </p>
        </div>
        <div class="contentRightBottom">
          <el-row class="ShopImg" v-for="(item, index) in orderDetailList" :key="index">
            <el-col :span="6">
              <img  :alt="item.goodsName  | defaultName" :src="item.goodsPic.split(',')[0] | defaultImage">
              <!--<img
                data-v-338e1239=""
                alt="复方丹参片"
                src="https://3akx.oss-cn-beijing.aliyuncs.com/upload/image/201905/656367be-1e18-4629-a3b1-105230c9a785.jpg"
              /> -->
            </el-col>
            <el-col :span="18" class="otherInfo">
              <h3>{{item.goodsName}} <span class="gift">{{item.goodsType==1?"[赠品]":item.goodsType==3?"[活动商品]":"[普通商品]"}}</span></h3>



              <!-- {{item.partSpecification}} -->
              <el-col>
                <span>药品规格：</span><span>{{item.specification}} </span>
              </el-col>
              <el-col>
                <span>件装规格：</span><span>{{item.partSpecification}} </span>
              </el-col>
              <el-col><span>单价：</span><span>￥{{item.originalPrice}}</span></el-col>
              <el-col><span>数量：</span><span>{{item.refundCount}}</span></el-col>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { RefundDetail,RefundCancel,ReShip,shippingDetail} from "api/order.js";
const Breadcrumb = () => import("components/common/Breadcrumb.vue");
export default {
  name: "AfterRefundDetail",
  data() {
     //表单验证
    let validateLogiNo = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入退货单号"));
      }else{
         callback();
      }
    };
     let validateLogi = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入退货单物流公司"));
      }else{
         callback();
      }
    };
    return {
      active: 1, //步骤条
      sn: "",//订单编号
      backId:"",//售后单号
      info: {
        status: "售后申请",   //售后状态
        type: 0,     //售后类型
        num: 600.0,
        acount:1,//补偿方式（1余额，2优惠卷）
        date: "",
        cause: "",
        examineTime:"",
        imgList: [],
      },
      orderDetailList:[],
      ruleForm: {
        logiNo: "",
        logi: "",
      },
      rules: {
        logiNo: [{ validator: validateLogiNo, trigger: "blur" }],
        logi: [{ validator: validateLogi, trigger: "blur" }],
      },
      shippingDetail:''  //退货信息
    };
  },
  methods: {

     //   点击提交 验证
     submitForm(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          let  Updatedata={
            logiNo:this.ruleForm.logiNo,
            logi:this.ruleForm.logi,
            refundId:this.backId
          }
          //提交物流信息
          ReShip(Updatedata).then((data)=>{
            if(data.data.code===400){
               this.$message.error(data.data.msg);
               return
            }else{
              this.$message({
                message:data.data.msg,
                type: 'success'
              });
              this.RefundDetailFun()
            }
          })

        } else {
          return false;
        }
      });
    },

    //重置
     resetForm(formName) {
        this.$refs[formName].resetFields();
      },


    // 撤销申请
    RefundCancelFun(){
      let ids={
        id:this.backId 
      }
      RefundCancel(ids).then((data)=>{
         if(data.data.code===400){
          this.$message(data.data.msg)

          }else{
            this.$message({
              message: data.data.msg,
              type: 'success'
            });
             this.$router.push({name:"AfterRefund"})
          }
      })
    },

    // 获取初始数据
    RefundDetailFun() {
        // 显示loading
    this.$loading({ fullscreen: true ,background:'#ffffff45'})
      RefundDetail(sessionStorage.getItem("backId")).then((data) => {
        this.orderDetailList=data.data.data.orderDetailList
        this.info.date=data.data.data.updateTime
         this.info.cause=data.data.data.question

         this.info.imgList=data.data.data.productPics.split(",")
        if(this.info.imgList[this.info.imgList.length-1]===''){
            this.info.imgList=this.info.imgList.splice(0,this.info.imgList.length-1);
         }
        this.info.examineTime=data.data.data.examineTime
         this.info.num=data.data.data.refundAmount
         this.info.type=data.data.data.refundMethod
         this.info.acount=data.data.data.refundType
          this.info.drawMethod=data.data.data.drawMethod
        this.sn = data.data.data.orderId;    //订单编号
        this.backId=data.data.data.id; //售后单号
        // 关闭loading
          this.$loading().close();
        // 售后状态
        switch (data.data.data.status) {
          case 1:
            this.info.status = "待审核";
            this.active=1
            break;
          case 2:
            this.info.status = "已通过";
             this.active=3
            break;
          case 3:
            this.info.status = "不通过";
             this.active=3
            break;
          case 4:
            this.info.status = "受理中";
             this.active=2
            break;
          case 5:
            this.info.status = "已受理";
             this.active=2
            break;
          case 6:
            this.info.status = "退货完成";
             this.active=2
            break;
          default:
            this.info.status = "撤销申请";
             this.active=0
            break;
        }

        if(this.active===2 && this.info.drawMethod === 2 && this.info.status === '受理中'){
           shippingDetail().then(res=>{
              this.shippingDetail=res.data.data
            })
        }
      });
    },
  },
  created() {
   
    this.RefundDetailFun();
   
  },
  components: {
    Breadcrumb,
  },
  filters: {
    numAdd(value) {
      return "￥" + value;
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  .content {
    min-height: 500px;
    background: #fff;
    margin-bottom: 30px;
    .contentLeft {
      border-right: 1px solid #f0f0f0;
      padding: 28px 20px;
      min-height: 500px;
      .contentLeftTop {
        padding-bottom: 20px;
        border-bottom: 1px solid #f0f0f0;
        p:nth-child(1) {
          span:nth-child(1) {
            font-size: 24px;
            color: #333;
            float: left;
            font-weight: bold;
            margin-bottom: 10px;
          }
          span:nth-child(2) {
            font-size: 14px;
            color: #fff;
            float: right;
            width: 76px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            display: block;
            background: #ff3333;
          }
        }
        p:nth-child(2) {
          color: #666666;
          font-size: 14px;
          clear: both;
          line-height: 25px;
        }
      }
      .contentLeftBottom {
        .backCon {
          .backTit {
            font-size: 14px;
            color: #999;
            width: 80px;
            line-height: 45px;
            margin-right: 36px;
            display: inline-block;
          }
          .imgBox{
            border: 1px solid #ccc;
            display: inline-block;
            margin-right: 5px;
          }
          .backMsg {
            font-size: 14px;
            color: #333;
            line-height: 45px;
          }
          img {
            display: inline-block;
            width: 74px;
            height: 74px;
            vertical-align: top;
          }
        }
      }
      .backLogisticsBox{
        border:1px dotted #c99f84;
        margin-top:15px;
        padding:15px 50px 0 50px;
        h3{
          color: #666;
          font-size:16px;
          font-weight: bold;
          text-align: center;
          margin-bottom:15px;
        }
          .wraper {
              display: flex;
              justify-content: left;
              align-items: center;
              /deep/.el-form{
                width: 100%;
              }
              .el-input {
                position: relative;
                font-size: 14px;
                display: inline-block;
                width:52%;
              }
              .SureBtn {
                background: #ff3333;
                color: #fff;
                border: 1px solid #ff3333;
                width: 136px;
                height: 36px;
                text-align: center;
                line-height: 36px;
                font-size: 15px;
                font-weight: bold;
                border-radius: 0;
                padding: 0;
              }
              .SureBtn.disabled {
                  background-color: #ddd;
                  border-color: #ddd;
                  color: #ed4345;
                  cursor: not-allowed; // 鼠标变化
                }
              .resetBtn{
                width:66px;
                height: 36px;
                text-align: center;
                line-height: 36px;
                font-size: 15px;
                font-weight: bold;
                border-radius: 0;
                padding: 0;
              }
            }
            /deep/.el-form-item__error {
              color: #f56c6c;
              font-size: 12px;
              line-height: 1;
              padding-top: 4px;
              position: absolute;
              top: 10px;
              left: calc(52% + 10px);
            }
      }
    }
    .contentRight {
      padding: 28px 20px;
      min-height: 500px;
      .contentRightTop {
        padding-bottom: 20px;
        height: 20px;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 16px;
        p {
          span {
            font-size: 18px;
            color: #333;
            float: left;
            font-weight: bold;
            margin-bottom: 10px;
          }
        }
      }
      .contentRightBottom {
        .ShopImg {
          color: #666;
          font-size: 12px;
          line-height: 17px;
          padding: 10px 24px;
          border: 1px solid #c99f84;
          background: #fffaf7;
          border-bottom: none;
          &:last-child {
            border-bottom: 1px solid #c99f84;
          }
          img {
            display: inline-block;
            width:80px;
            height:80px;
            margin-top:6px
          }
          .otherInfo {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
           height: 92px;
            h3 {
              color: #333333;
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
            }
            .gift {
              color: #ff9999;
            }
          }
        }
      }
    }
  }
}

// 步骤条
/deep/.el-step__icon {
  width: 22px !important;
  height: 22px !important;
  font-size: 14px !important;
  .round_solid1 {
    width: 20px;
    height: 20px;
    display: block;
    color: #ccc;
    border-radius: 50%;
    border: 1px solid#ccc;
    &:before {
      content: "1";
      line-height: 20px;
      text-align: center;
    }
  }
  .round_solid2 {
    width: 20px;
    height: 20px;
    display: block;
    color: #ccc;
    border-radius: 50%;
    border: 1px solid#ccc;
    &:before {
      content: "2";
      line-height: 20px;
      text-align: center;
    }
  }
  .round_solid3 {
    width: 20px;
    height: 20px;
    display: block;
    color: #ccc;
    border-radius: 50%;
    border: 1px solid#ccc;
    &:before {
      content: "3";
      line-height: 20px;
      text-align: center;
    }
  }
}
/deep/.el-step__title.is-process {
  color: #ccc;
}
/deep/.el-step__head.is-finish {
  /deep/.el-step__icon {
    width: 24px !important;
    height: 24px !important;
    font-size: 14px !important;
    .round_solid1 {
      color: #ff3333;
      border: 2px solid#FF3333;
      font-weight: bold;
    }
    .round_solid2 {
      color: #ff3333;
      border: 2px solid#FF3333;
      font-weight: bold;
    }
    .round_solid3 {
      color: #ff3333;
      border: 2px solid#FF3333;
      font-weight: bold;
    }
  }
}
/deep/.el-step__title.is-finish {
  color: #ff3333;
  font-weight: bold;
}
.el-steps--simple {
  padding: 13px 8%;
  border-radius: 4px;
  margin-bottom: 20px;
  background: #fff;
}
</style>
